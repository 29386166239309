import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IconArrow, IconBadge123 } from '@/assets/svgs/system';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { BreakPoint } from '@/components/styles/media';
import {
  currentNavigationRouteSelector,
  globalNavigationSelector,
  updateVisitedNavigationIdsAction,
  visitedNavigationIdsSelector,
} from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { scheduleEventsSelector } from '@/features/global/globalNavigationBar/scheduleEvents/scheduleEventsSlice';
import { isHydrateNeededSelector } from '@/features/global/isHydrateNeeded';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useLoggedUser } from '@/hooks/useLoggedUser';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import { getParsedCookie } from '@/utils/cookie';
import { findLeafNavigation } from '@/utils/navigation';

import { usePageContext } from '../PageContext';
import { GlobalNavigationBarLink } from './GlobalNavigationBarLink';
import * as styles from './GlobalNavigationBarTop.styles';

export const GlobalNavigationBarTopAccount = (): ReactJSX.Element => {
  const router = useRouter();
  const pageContext = usePageContext();
  const eventStatus = useSelector(scheduleEventsSelector).events;
  const [returnURL, setReturnURL] = useState({ login: '', signup: '' });
  useEffect(() => {
    // SSR 시에는 window 가 없기 때문에 useEffect로 사용
    const params = new URLSearchParams();
    params.append('return_url', new URL(router.asPath, window.location.href).toString() || window.location.href);

    setReturnURL({ login: `/account/login?${params.toString()}`, signup: `/account/signup?${params.toString()}` });
  }, [router.asPath]);

  const user = useLoggedUser();
  const isHydrationNeeded = useSelector(isHydrateNeededSelector);

  if (isHydrationNeeded) {
    return <></>;
  }

  if (user) {
    return (
      <ul css={styles.topSecondaryItemsStyle}>
        <li css={styles.topItemStyle}>
          <TrackClickEvent screenName={pageContext.screenName} target="charge_cash" params={pageContext.params}>
            <a href="/order/checkout/cash" css={styles.topItemLinkStyle}>
              <span>캐시충전</span>
              {eventStatus?.double_point && (
                <IconBadge123 css={styles.topItemLinkIconStyle} aria-label="리디 캐시 더블 포인트 충전" />
              )}
            </a>
          </TrackClickEvent>
        </li>
      </ul>
    );
  }

  return (
    <ul css={styles.topSecondaryItemsStyle}>
      <li css={styles.topItemStyle}>
        <TrackClickEvent screenName={pageContext.screenName} target="signup" params={pageContext.params}>
          <a href={returnURL.signup} css={styles.topItemLinkStyle}>
            회원가입
          </a>
        </TrackClickEvent>
      </li>
      <li css={styles.topItemStyle}>
        <TrackClickEvent screenName={pageContext.screenName} target="login" params={pageContext.params}>
          <a href={returnURL.login} css={styles.topItemLinkStyle}>
            로그인
          </a>
        </TrackClickEvent>
      </li>
    </ul>
  );
};

export const GlobalNavigationBarTop = (): ReactJSX.Element => {
  const dispatch = useAppDispatch();

  const pageContext = usePageContext();
  const globalNavigation = useSelector(globalNavigationSelector);
  const currentNavigationRoute = useSelector(currentNavigationRouteSelector);
  const visitedNavigationIds = useSelector(visitedNavigationIdsSelector);
  useEffect(() => {
    if (!visitedNavigationIds) {
      dispatch(updateVisitedNavigationIdsAction(getParsedCookie(document.cookie)?.ridi_nav));
    }
  }, [dispatch, visitedNavigationIds]);

  const navigationItems = useMemo(
    () =>
      globalNavigation?.map(item => ({
        ...item,
        isSelected: currentNavigationRoute?.some(route => route.id === item.id),
        leafNavigation: findLeafNavigation(item, visitedNavigationIds || new Set()),
      })),
    [visitedNavigationIds, globalNavigation, currentNavigationRoute],
  );

  const isHiddenByNavigation = !currentNavigationRoute;
  const isMobile = useResponsiveIsBelow(BreakPoint.DesktopSmall);

  return (
    <>
      <div css={[styles.globalNavigationBarTopStyle, isHiddenByNavigation && styles.globalNavigationBarTopHiddenStyle]}>
        <div css={styles.topBarStyle}>
          <div css={styles.topItemsWrapperStyle}>
            <ul css={styles.topItemsStyle}>
              {navigationItems?.map(item => (
                <li key={item.id} css={styles.topItemStyle}>
                  <GlobalNavigationBarLink
                    css={[
                      styles.topItemLinkStyle,
                      item.isSelected && styles.topItemSelectedLinkStyle,
                      !visitedNavigationIds && styles.disableClickWhileHydrationStyle,
                    ]}
                    trackingParams={{
                      screenName: pageContext.screenName,
                      target: 'navigation_top',
                      params: {
                        ...pageContext.params,
                        navigation_title: item.title,
                        navigation_path: item.leafNavigation.path,
                      },
                    }}
                    href={item.leafNavigation.path}>
                    {item.title}
                  </GlobalNavigationBarLink>
                </li>
              ))}

              <li css={styles.topItemStyle}>
                <TrackClickEvent
                  screenName={pageContext.screenName}
                  target="navigation_top"
                  params={{
                    ...pageContext.params,
                    navigation_title: '셀렉트',
                    navigation_path: 'https://select.ridibooks.com',
                  }}>
                  <a href="https://select.ridibooks.com" css={styles.topItemLinkStyle}>
                    셀렉트
                    <IconArrow
                      css={styles.topItemExternalLinkStyle}
                      size={9}
                      thick={isMobile ? 1.4 : 1}
                      direction="right"
                      aria-label="이동"
                    />
                  </a>
                </TrackClickEvent>
              </li>
            </ul>

            <GlobalNavigationBarTopAccount />
          </div>
        </div>

        <div css={styles.topPlaceholderStyle} />
      </div>
      {!isHiddenByNavigation && <div css={styles.maskStyle} />}
    </>
  );
};
