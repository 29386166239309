import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  IconDelete,
  IconGNBCart,
  IconGNBLibrary,
  IconGNBMyRidi,
  IconGNBNotification,
  IconLogoRIDI,
  IconSearchSearch,
} from '@/assets/svgs/system';
import { SearchBar, SearchBarHandle } from '@/components/common/Search';
import { a11y } from '@/components/styles/reset';
import { RIDITheme } from '@/components/styles/themes';
import { cartCountSelector, fetchCartCountAction } from '@/features/cart/cartSlice';
import {
  currentGlobalNavigationSelector,
  genreChangeSheetSelector,
} from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import {
  fetchScheduleEventsAction,
  scheduleEventsSelector,
} from '@/features/global/globalNavigationBar/scheduleEvents/scheduleEventsSlice';
import { isHydrateNeededSelector } from '@/features/global/isHydrateNeeded';
import { isRidiAppSelector } from '@/features/global/variables/variablesSlice';
import { fetchUnreadCountAction, unreadCountSelector } from '@/features/notification/notificationSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useLoggedUser } from '@/hooks/useLoggedUser';
import { useRouteFetchEffect } from '@/hooks/useRouteFetchEffect';
import { GenreChangeSheet } from '@/models/backendsApi/v2/Navigation/NavigationType';
import { getStorageItemJSON, LOCAL_STORAGE_KEYS, setStorageItemJSON } from '@/utils/storage';

import { TrackClickEvent } from '../EventClient/TrackClickEvent';
import { usePageContext } from '../PageContext/PageContextProvider';
import * as styles from './GlobalNavigationBar.styles';
import { GlobalNavigationBarLink } from './GlobalNavigationBarLink';
import { GlobalNavigationBarTop } from './GlobalNavigationBarTop';

export const getGenreChangeSheet = (theme: RIDITheme['colorScheme'], genreChangeSheet?: GenreChangeSheet['web']) => {
  if (!genreChangeSheet) {
    return null;
  }

  return genreChangeSheet[theme] ?? genreChangeSheet['light' as const];
};

export const getLogoImageUrl = (
  theme: RIDITheme['colorScheme'],
  findingTitle?: string,
  genreChangeSheet?: GenreChangeSheet['web'],
) => {
  if (!findingTitle) {
    return null;
  }

  const genreChangeSheetWeb = getGenreChangeSheet(theme, genreChangeSheet);
  return genreChangeSheetWeb?.find(({ title }) => title === findingTitle)?.image_url;
};

const GNBLogo = (): ReactJSX.Element => {
  const { screenName } = usePageContext();
  const theme = useTheme();
  const themeColor = theme.colorScheme;
  const genreChangeSheet = useSelector(genreChangeSheetSelector);
  const currentGlobalNavigation = useSelector(currentGlobalNavigationSelector);

  const GNBRidiLogo = useMemo(() => {
    const targetTitle = '리디';
    const logoImageUrl = getLogoImageUrl(themeColor, targetTitle, genreChangeSheet);

    if (logoImageUrl) {
      return (
        <div css={styles.logoStyle}>
          <img css={styles.logoImageStyle} src={logoImageUrl} alt={targetTitle} />
        </div>
      );
    }

    return <IconLogoRIDI css={styles.logoStyle} />;
  }, [genreChangeSheet, themeColor]);

  const GNBGenreLogo = useMemo(() => {
    const logoImageUrl = getLogoImageUrl(themeColor, currentGlobalNavigation?.title, genreChangeSheet);

    if (logoImageUrl) {
      return (
        <div css={styles.logoGenreIconStyle}>
          <img css={styles.logoImageStyle} src={logoImageUrl} alt={currentGlobalNavigation?.title} />
        </div>
      );
    }

    return <></>;
  }, [currentGlobalNavigation?.title, genreChangeSheet, themeColor]);

  return (
    <div css={styles.logoWrapperStyle}>
      <GlobalNavigationBarLink
        css={styles.logoAnchorStyle}
        href="/"
        trackingParams={{
          screenName,
          target: 'ridibooks',
        }}>
        <div css={styles.logoWrapperStyle}>
          {GNBRidiLogo}
          {GNBGenreLogo}
        </div>
        <span css={a11y}>리디 홈으로 이동</span>
      </GlobalNavigationBarLink>
    </div>
  );
};

interface GNBButtonLinkProps {
  className?: string;
  children: ReactNode;
  path: string;
  label: string;
  target: string;
  addOn?: ReactNode;
}

const GNBButtonLink = ({ className, children, path, label, target, addOn }: GNBButtonLinkProps) => {
  const pageContext = usePageContext();

  return (
    <div css={styles.buttonItemStyle} className={className}>
      <TrackClickEvent screenName={pageContext.screenName} target={target} params={pageContext.params}>
        <a css={styles.buttonAnchorStyle} href={path}>
          <span css={a11y}>{label}</span>
          {children}
          {addOn}
        </a>
      </TrackClickEvent>
    </div>
  );
};

const GNBNotificationButton = () => {
  const user = useLoggedUser();
  const router = useRouter();
  const unreadCount = useSelector(unreadCountSelector);

  const isInNotification = router.pathname === '/notification';

  return (
    <GNBButtonLink
      label="알림"
      path="/notification"
      target="notification"
      addOn={
        !!user &&
        !!unreadCount &&
        !isInNotification && (
          <div css={styles.notificationAddOnStyle}>
            <span css={a11y}>{unreadCount}개 읽지 않음</span>
          </div>
        )
      }>
      <IconGNBNotification />
    </GNBButtonLink>
  );
};

const GNBCartButton = () => {
  const user = useLoggedUser();
  const cartCount = useSelector(cartCountSelector);

  return (
    <GNBButtonLink
      label="카트"
      path="/cart"
      target="cart"
      addOn={
        !!user &&
        !!cartCount && (
          <div css={styles.cartAddOnStyle}>
            {cartCount}
            <span css={a11y}>개의 아이템</span>
          </div>
        )
      }>
      <IconGNBCart />
    </GNBButtonLink>
  );
};

const GNBMyRIDIButton = () => (
  <GNBButtonLink label="마이리디" path="/account/myridi" target="myridi">
    <IconGNBMyRidi />
  </GNBButtonLink>
);

const GNBLibraryButton = () => (
  <GNBButtonLink label="내 서재" path="/library" target="library">
    <IconGNBLibrary />
  </GNBButtonLink>
);

const GNBSearchButton = ({ className, onClick }: { className?: string; onClick: () => void }) => {
  const pageContext = usePageContext();

  return (
    <div className={className} css={styles.buttonItemStyle}>
      <TrackClickEvent screenName={pageContext.screenName} target="search" params={pageContext.params}>
        <button type="button" css={styles.buttonContentStyle} onClick={onClick}>
          <span css={a11y}>검색</span>
          <IconSearchSearch />
        </button>
      </TrackClickEvent>
    </div>
  );
};

const GNBLoginButton = () => {
  const router = useRouter();
  const pageContext = usePageContext();
  const [returnURL, setReturnURL] = useState('');

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('return_url', new URL(router.asPath, window.location.href).toString() || window.location.href);
    setReturnURL(`/account/login?${params.toString()}`);
  }, [router.asPath]);

  return (
    <TrackClickEvent screenName={pageContext.screenName} target="login" params={pageContext.params}>
      <a css={styles.loginButtonStyle} href={returnURL}>
        로그인
      </a>
    </TrackClickEvent>
  );
};

const GNBButtons = ({ children }: { children?: ReactNode }) => {
  const user = useLoggedUser();
  const isHydrationNeeded = useSelector(isHydrateNeededSelector);

  return (
    <div css={styles.buttonsWrapperStyle}>
      <div css={[styles.buttonsGroupStyle, styles.buttonsGroupMobileHideStyle(isHydrationNeeded)]}>{children}</div>

      <div css={[styles.buttonsGroupStyle, styles.buttonsGroupMobileHideStyle(isHydrationNeeded || !user)]}>
        <GNBNotificationButton />
        <GNBCartButton />
        <GNBLibraryButton />
        <GNBMyRIDIButton />
      </div>

      <div css={[styles.buttonsGroupStyle, styles.buttonsGroupMobileShowStyle(!isHydrationNeeded && !user)]}>
        <GNBLoginButton />
      </div>
    </div>
  );
};

// 123 기간에 숨긴다면 다음 123기간까지는 숨겨지도록 (> 3, <= 25)
const CASHCHARGE_TOOLTIP_DISMISS_PERIOD = 1000 * 60 * 60 * 24 * 10;
const GNBCashChargeTooltip = () => {
  const pageContext = usePageContext();
  const user = useLoggedUser();
  const eventStatus = useSelector(scheduleEventsSelector).events;
  const [hasDismissed, setHasDismissed] = useState<boolean | null>(null);

  useEffect(() => {
    const dismissedAt = getStorageItemJSON('local', LOCAL_STORAGE_KEYS.CASHCHARGE_TOOLTIP_DISMISSED_AT);
    if (!dismissedAt) {
      setHasDismissed(false);
      return;
    }

    setHasDismissed(dismissedAt + CASHCHARGE_TOOLTIP_DISMISS_PERIOD > Date.now());
  }, []);

  const onDismiss = () => {
    setStorageItemJSON('local', LOCAL_STORAGE_KEYS.CASHCHARGE_TOOLTIP_DISMISSED_AT, Date.now());
    setHasDismissed(true);
  };

  const shouldShowTooltip = hasDismissed === false && eventStatus?.double_point && user;
  return (
    <TrackClickEvent screenName={pageContext.screenName} target="charge_cash_tooltip" params={pageContext.params}>
      <button
        css={[styles.cashChargeTooltipStyle, !shouldShowTooltip && styles.cashChargeTooltipHideStyle]}
        type="button"
        onClick={onDismiss}>
        <span css={styles.cashChargeTooltipTextStyle}>캐시충전은 MY 안에 있어요!</span>

        <IconDelete css={styles.cashChargeTooltipIconStyle} aria-label="닫기" />
      </button>
    </TrackClickEvent>
  );
};

interface GlobalNavigationBarProps {
  className?: string;
  showSearch?: boolean;
  showMyRIDITooltip?: boolean;
}

export const GlobalNavigationBar = ({
  className,
  showSearch = false,
  showMyRIDITooltip = false,
}: GlobalNavigationBarProps): ReactJSX.Element => {
  const isInApp = useSelector(isRidiAppSelector);
  const user = useLoggedUser();
  const searchBarHandle = useRef<SearchBarHandle | null>(null);
  const onOpenSearch = useCallback(() => {
    if (searchBarHandle.current) {
      searchBarHandle.current.focus();
    }
  }, []);

  const dispatch = useAppDispatch();
  const shouldFetchUserData = !!user;
  const fetchData = useCallback(async () => {
    const fetchList: Promise<unknown>[] = [];
    fetchList.push(dispatch(fetchScheduleEventsAction({})));

    if (shouldFetchUserData) {
      fetchList.push(dispatch(fetchCartCountAction({})));
      fetchList.push(dispatch(fetchUnreadCountAction({})));
    }

    await Promise.all(fetchList);
  }, [dispatch, shouldFetchUserData]);
  useRouteFetchEffect(fetchData, [fetchData]);

  if (isInApp) {
    return <></>;
  }

  return (
    <>
      <GlobalNavigationBarTop />
      <div css={styles.wrapperStyle} className={className}>
        <header css={styles.containerStyle}>
          <nav css={styles.navigationStyle}>
            <GNBLogo />

            <SearchBar
              css={[styles.searchBarStyle, !showSearch && styles.searchBarHideStyle]}
              focusedCss={styles.searchBarShowStyle}
              ref={searchBarHandle}
            />

            <GNBButtons>
              {!showSearch && <GNBSearchButton css={styles.searchButtonStyle} onClick={onOpenSearch} />}
              {showMyRIDITooltip && <GNBCashChargeTooltip />}
            </GNBButtons>
          </nav>
        </header>
      </div>
    </>
  );
};
