import { css, SerializedStyles } from '@emotion/react';

import { pageContainerStyle, ZIndexLayer } from '@/components/styles';
import { BreakPoint, greaterThan, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  width: 100%;
  background: ${theme.colors.bgBase};
  position: relative;
`;

export const containerStyle = css`
  ${pageContainerStyle};
  position: relative;
  padding: 0;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0;
    `,
  )};
`;

export const navigationStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 16px;
  padding-left: 22px;
  padding-right: 18px;
  padding-bottom: 16px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 9px 0;
      flex-wrap: wrap;
    `,
  )};
`;

export const searchBarStyle = css`
  width: 260px;
  margin-right: 8px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      order: 3;
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    `,
  )};
`;

export const logoWrapperStyle = css`
  display: flex;
  margin: 0;
  margin-right: auto;
`;

export const logoAnchorStyle = css`
  display: inline-flex;
`;

export const logoStyle = css`
  display: flex;

  width: auto;
  height: 40px;
  padding: 8px 6px 9px 8px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 42px;
      padding: 13px 5px 14px 16px;
    `,
  )};

  ${orBelow(
    346,
    css`
      height: 32px;
      padding: 10px 4px 10px 16px;
    `,
  )};
`;

export const logoImageStyle = css`
  height: calc(40px - 8px - 9px);

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: calc(42px - 13px - 14px);
    `,
  )};

  ${orBelow(
    346,
    css`
      height: calc(32px - 10px - 10px);
    `,
  )};
`;

export const logoGenreIconStyle = css`
  display: flex;
  width: auto;
  height: 40px;
  padding: 8px 8px 9px 0;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 42px;
      padding: 13px 0 14px 0;
    `,
  )};

  ${orBelow(
    346,
    css`
      height: 32px;
      padding: 10px 0 10px 0;
    `,
  )};
`;

export const buttonsWrapperStyle = css`
  display: flex;
  align-items: center;
  position: relative;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-right: 8px;
    `,
  )};

  ${orBelow(
    346,
    css`
      margin-left: 4px;
    `,
  )};
`;

export const buttonsGroupStyle = css`
  display: flex;
  align-items: center;
`;

export const buttonsGroupMobileHideStyle = (hide: boolean): SerializedStyles => css`
  ${hide &&
  orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: none;
    `,
  )}
`;

export const buttonsGroupMobileShowStyle = (show: boolean): SerializedStyles => css`
  display: none;
  ${show &&
  orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: flex;
    `,
  )}
`;

export const buttonItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  font-size: 28px;
  margin: 0 6px;
  color: ${theme.colors.fillPrimary};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 24px;
      margin: 0;
    `,
  )};
`;

export const buttonContentStyle = css`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: transparent;
  padding: 6px;
  padding-top: 7px;
  padding-bottom: 5px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 9px 8px;
    `,
  )};
`;

export const buttonAnchorStyle = css`
  ${buttonContentStyle};
`;

const addOnStyle = (theme: RIDITheme): SerializedStyles => css`
  position: absolute;
  top: 7px;
  right: 6px;
  pointer-events: none;
  background: ${theme.colors.red};
  color: ${theme.colors.white};
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      top: 9px;
      right: 8px;
    `,
  )};
`;

export const cartAddOnStyle = (theme: RIDITheme): SerializedStyles => css`
  min-width: 17px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 3px 5px;
  border-radius: 12px;
  transform: translate(50%, -50%);

  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: center;

  ${addOnStyle(theme)};
`;

export const notificationAddOnStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 2px;

  ${addOnStyle(theme)};
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 3px;
      height: 3px;
    `,
  )};
`;

export const searchButtonStyle = css`
  &,
  & > * {
    -webkit-tap-highlight-color: transparent;
  }

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      display: none;
    `,
  )};
`;

export const searchBarHideStyle = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    `,
  )};
`;

export const searchBarShowStyle = css`
  opacity: 1 !important;
  pointer-events: auto !important;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      position: fixed !important;
    `,
  )};
`;

export const loginButtonStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  border: 1px solid ${theme.colors.grey200};
  border-radius: 15px;

  padding: 0 13px;
  padding-top: 7px;
  padding-bottom: 6px;
  margin-left: 8px;
  margin-right: 8px;

  color: ${theme.colors.fillPrimary};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: center;

  // FIXME overriding books-backend's page_base_renewal.css when /partial
  &:link,
  &:visited {
    color: ${theme.colors.fillPrimary};
  }
`;

export const cashChargeTooltipStyle = (theme: RIDITheme): SerializedStyles => css`
  position: absolute;
  right: 5px;
  bottom: -28px;
  z-index: ${ZIndexLayer.LEVEL_TOOLTIP};

  padding-left: 12px;
  padding-right: 10px;
  height: 26px;

  display: inline-flex;
  align-items: center;

  background: ${theme.colors.fillSecondary};
  border-radius: 26px;
  opacity: 1;
  transition: all 0.2s ease;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: -8px;
    right: 13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent ${theme.colors.fillSecondary} transparent;
  }

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      display: none;
    `,
  )};
`;

export const cashChargeTooltipHideStyle = css`
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;

export const cashChargeTooltipTextStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.white};
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.01em;
  white-space: nowrap;
`;

export const cashChargeTooltipIconStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.white};
  font-size: 12px;
  margin-left: 4px;
`;
